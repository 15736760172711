<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Dealers</v-toolbar-title>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
        >
          <v-icon
            left
            dark
          >mdi-printer</v-icon>Print
        </v-btn>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
        >
          <v-icon
            left
            dark
          >mdi-import</v-icon>Import
        </v-btn>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
        >
          <v-icon
            left
            dark
          >mdi-export</v-icon>Export
        </v-btn>
        <v-btn
          class="mr-1"
          outlined
          v-if="docRights && docRights.write"
          color="toolbarIcon"
          text
          to="/dealer/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              dense
              v-if="docRights.read == true"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="bpMasterData"
              :search="search"
            >
              <template v-slot:item.Name="{ item }">
                <v-btn
                  class="btnLink"
                  color="flatButton"
                  text
                  :to="`/dealer/view/${item.id}`"
                >{{ item.Name }}</v-btn>
              </template>
            </v-data-table>

            <v-alert
              type="error"
              v-if="docRights.read == false"
            >You are not authorised to read data</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    docRights: {},
    search: "",
    bpMasterData: [],
    headers: [
      { text: "Name", value: "Name" },
      { text: "Phone", value: "Phone" },
      { text: "Type", value: "Type" },
      { text: "Location Abrreviation", value: "LocationAbrn" },
      { text: "Town", value: "Town" },
      { text: "County", value: "County" },
      { text: "Discount", value: "Discount" },
    ],
  }),
  methods: {
    getDealer() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/dealer_masterdata`)
        .then((res) => {
          console.log(res, "Dealers");
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    checkRights() {
      const self = this;
      this.$store
        .dispatch("get", `/authorization/check-if-permitted/1`)
        .then((res) => {
          self.docRights = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDealer();
    this.checkRights();
  },
};
</script>